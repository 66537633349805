<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Tipos de justificativa de falta</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Tipos de justificativa de
                  <span>falta</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibir"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de justificativas
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click.prevent="alteraFastNavegacao('criar')"
                            >
                              <small>+ Cadastrar justificativa</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Filtro pelo nome do motivo"
                              @keyup="filtroNomeMotivo"
                            >
                          </div>                          
                        </div>
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr class="text-center">
                                  <th class="text-center">
                                    <small class="font-weight-bold">Motivo da falta</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Abona falta</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Professor</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Secretaria</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Tutor</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastJustificativas.loading">
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastJustificativas.paginacao.length">
                                <tr
                                  v-for="(e, index) in fastJustificativas.paginacao"
                                  :key="index"
                                >
                                  <td class="align-middle text-center">
                                    <small>{{ e.nome_motivo_falta }}</small>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.abona_falta"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.permite_lancamento_professor"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.permite_lancamento_secretaria"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.permite_lancamento_tutor"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="text-center align-middle">                                  
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalEditarFastJustificativa(e)"
                                    >
                                      <small>Editar</small>
                                    </button>
                                    <button
                                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                      @click="exibeModalExcluirFastJustificativa(e)"
                                    >
                                      <small>Excluir</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Nenhuma justificativa cadastrada
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="10"
                              :items="fastJustificativas.filtro"
                              @changePage="
                                fastJustificativas.paginacao = $event
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.criar"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Nova sala
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome da justificativa <small class="text-danger">*</small></label>
                              <input
                                v-model="fastJustificativaNovo.nome_motivo_falta"
                                type="text"
                                :class="!fastJustificativaNovo.nome_motivo_falta ? 'form-control border border-danger' : 'form-control'"
                                maxlength="100"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Abona falta</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaNovo.abona_falta"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Professor pode lançar</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaNovo.permite_lancamento_professor"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Secretaria pode lançar</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaNovo.permite_lancamento_secretaria"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Tutor pode lançar</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaNovo.permite_lancamento_tutor"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Plataformas filhas</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaNovo.deriva_plataforma_filha"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  v-if="!fastBtnLoading"
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                  @click="criarFastJustificativa()"
                                >
                                  Cadastrar justificativa
                                </button>
                                <button
                                  v-else
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                >
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Aguarde...
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-secondary mr-1"
                                  @click="getfastJustificativas($route.params.id_plataforma)"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.editar"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Nova sala
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome da justificativa <small class="text-danger">*</small></label>
                              <input
                                v-model="fastJustificativaEditar.nome_motivo_falta"
                                type="text"
                                :class="!fastJustificativaEditar.nome_motivo_falta ? 'form-control border border-danger' : 'form-control'"
                                maxlength="100"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Abona falta</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaEditar.abona_falta"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Professor pode lançar</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaEditar.permite_lancamento_professor"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Secretaria pode lançar</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaEditar.permite_lancamento_secretaria"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Tutor pode lançar</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaEditar.permite_lancamento_tutor"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Plataformas filhas</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastJustificativaEditar.deriva_plataforma_filha"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  v-if="!fastBtnLoading"
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                  @click="editarFastJustificativa()"
                                >
                                  Salvar alterações
                                </button>
                                <button
                                  v-else
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                >
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Aguarde...
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-secondary mr-1"
                                  @click="getfastJustificativas($route.params.id_plataforma)"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">               
                <a
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExcluirJustificativa"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Excluir justificativa?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirJustificativa')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>A justificativa <span class="text-danger">{{ fastJustificativaEditar.nome_motivo_falta }} </span> será excluída e essa operação não poderá ser revertida.</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="!fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirFastJustificativa()"
                >
                  Confirmar
                </button>
                <button
                  v-else
                  href="#"
                  type="button"
                  class="btn btn-danger"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Aguarde...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaSala",
  components: {Pagination},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "100%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastBtnLoading: false,
      fastNavegacao: {
        exibir: true,
        criar: false,
        alterar: false,
      },
      fastJustificativas: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastJustificativaNovo: {
        id_justifica: 0,
        nome_motivo_falta: "",
        abona_falta: false,
        permite_lancamento_professor: false,
        permite_lancamento_secretaria: false,
        permite_lancamento_tutor: false,
        id_plataforma: this.$route.params.id_plataforma,
        deriva_plataforma_filha: false,                
      },
      fastJustificativaEditar: {
        id_justifica: 0,
        nome_motivo_falta: "",
        abona_falta: "",
        permite_lancamento_professor: "",
        permite_lancamento_secretaria: "",
        permite_lancamento_tutor: "",
        id_plataforma: 0,
        deriva_plataforma_filha: "",                
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getfastJustificativas(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getfastJustificativas(id_plataforma){
      this.alteraFastNavegacao('exibir')   
      this.fastJustificativas.loading = true
      this.promiseGetFastApi("api/fast_secretaria_justificativa_falta/lista", "id_plataforma=" + id_plataforma).then(obj => {
        if (obj.length) {
          this.fastJustificativas.total = obj
          this.fastJustificativas.filtro = obj
        }
        else {
          this.fastJustificativas.total = []
          this.fastJustificativas.filtro = []
        }            
        this.fastJustificativas.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar justificativas", "error");
        this.fastJustificativas.loading = false
      })
    },
    filtroNomeMotivo(e) {
      let text = e.target.value;
      this.fastJustificativas.filtro = this.fastJustificativas.total.filter(
        (e) => {
          return (
            e.nome_motivo_falta.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
          );
        }
      );
    },
    async criarFastJustificativa(){
      this.fastBtnLoading = true   
      let erros = []
      if (!this.fastJustificativaNovo.nome_motivo_falta) erros.push("O nome é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {        
          this.promisePostFastApi(this.fastJustificativaNovo, "api/fast_secretaria_justificativa_falta/insere").then((res) => {
            this.fastJustificativas.total = res
            this.fastJustificativas.filtro = res
            this.fastBtnLoading = false
            this.exibeToasty("Justificativa cadastrada com sucesso", "success")
            this.alteraFastNavegacao('exibir')      
            
            this.fastJustificativaNovo = {
              id_justifica: 0,
              nome_motivo_falta: "",
              abona_falta: false,
              permite_lancamento_professor: false,
              permite_lancamento_secretaria: false,
              permite_lancamento_tutor: false,
              id_plataforma: this.$route.params.id_plataforma,
              deriva_plataforma_filha: false,                
            }
          }).catch((e) => {
              this.exibeToasty("Erro ao criar justificativa", "error");
              this.fastBtnLoading = false
          });
      }      
    },
    exibeModalEditarFastJustificativa(justificativa) {      
      this.fastBtnLoading = false
      this.fastJustificativaEditar = justificativa 
      this.alteraFastNavegacao('editar')    
    },
    async editarFastJustificativa() {   
      this.fastBtnLoading = true   
      let erros = []        
      if (!this.fastJustificativaEditar.nome_motivo_falta) erros.push("O nome é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {
          this.promisePostFastApi(this.fastJustificativaEditar, "api/fast_secretaria_justificativa_falta/atualiza").then(res => {
            this.exibeToasty("Salvo com sucesso", "success");  
            this.alteraFastNavegacao('exibir')
            this.fastJustificativas.total = res
            this.fastJustificativas.filtro = res
            this.fastBtnLoading = false
          }).catch(e => {
              this.exibeToasty("Erro ao salvar alterações", "error");
              this.fastBtnLoading = false
          });
      }        
    },
    exibeModalExcluirFastJustificativa(justificativa) {
      this.fastBtnLoading = false
      this.fastJustificativaEditar = justificativa
      this.showModal("modalExcluirJustificativa")
    },
    async excluirFastJustificativa(){
      this.fastBtnLoading = true   
      this.promisePostFastApi(this.fastJustificativaEditar, "api/fast_secretaria_justificativa_falta/exclui").then(e => {
        this.exibeToasty("Justificativa excluído com sucesso", "success")       
        this.getfastJustificativas(this.$route.params.id_plataforma) 
        this.hideModal("modalExcluirJustificativa")
        this.fastBtnLoading = false
      }).catch(e => {
          this.exibeToasty("Erro ao excluir", "error");
          this.fastBtnLoading = false
      });
    },
  },
};
</script>

<style scope>
</style>
